import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frequently-asked-questions"
    }}>{`Frequently Asked Questions`}</h1>
    <h2 {...{
      "id": "what-are-the-credentials-for-local-development"
    }}>{`What are the credentials for local development?`}</h2>
    <ul>
      <li parentName="ul">{`host: `}<a parentName="li" {...{
          "href": "https://local.kualibuild.com/app/builder"
        }}>{`https://local.kualibuild.com/app/builder`}</a></li>
      <li parentName="ul">{`username: kualiadmin`}</li>
      <li parentName="ul">{`password: admin`}</li>
    </ul>
    <h2 {...{
      "id": "where-can-i-see-emails-when-developing-locally"
    }}>{`Where can I see emails when developing locally?`}</h2>
    <p><a parentName="p" {...{
        "href": "http://localhost:1080/"
      }}>{`http://localhost:1080/`}</a></p>
    <h2 {...{
      "id": "i-often-need-to-restart-services-when-booting-up-any-tips"
    }}>{`I often need to restart services when booting up. Any tips?`}</h2>
    <p>{`Yeah, it's bogus. For now you can add the following to your .zshrc/.bashrc/.bash_profile. Then just run `}<inlineCode parentName="p">{`build_restart_api`}</inlineCode>{` after you `}<inlineCode parentName="p">{`build up`}</inlineCode>{` and everything should run smoothly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`function docker-restart () {
  docker container restart $(docker ps | grep $1 | awk '{print $1}')
}

alias build_restart_api='
  docker-restart workflows-api;
  docker-restart forms-api;
  docker-restart builder-api;
  docker-restart identity;
'
`}</code></pre>
    <h2 {...{
      "id": "is-there-a-quick-way-to-tail-the-logs-of-a-local-service"
    }}>{`Is there a quick way to tail the logs of a local service?`}</h2>
    <p>{`I'm glad you asked! Add the following snippet to your .zshrc/.bashrc/.bash_profile and then run something like `}<inlineCode parentName="p">{`docker-logs builder-api`}</inlineCode>{`. It filters the names of your running docker containers and displays logs if it gets one and only one match. So you can shorten it in some cases and do, for example, `}<inlineCode parentName="p">{`docker-logs flow`}</inlineCode>{` to get the logs for `}<inlineCode parentName="p">{`workflows-api`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`function docker-logs () {
    docker logs -f $(docker ps | grep $1 | awk '{print $1}')
}
`}</code></pre>
    <h2 {...{
      "id": "how-can-i-develop-on-another-service-alongside-builder-ui"
    }}>{`How can I develop on another service alongside builder-ui?`}</h2>
    <p>{`Great question! Any one of our services can be developed on by itself by pulling down the service code and running `}<inlineCode parentName="p">{`build up`}</inlineCode>{`. This will find the `}<inlineCode parentName="p">{`build.service.json5`}</inlineCode>{` file within that project and set everything up based on it.`}</p>
    <p>{`To develop on more than one service, you'll need to create a file called `}<inlineCode parentName="p">{`build.services.json5`}</inlineCode>{` (note the plural "services" in the name) and add something like the following to it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json5"
      }}>{`{
  run: [
    '../builder-ui',
    '../platform-api/packages/builder-api-node',
    '../platform-api/packages/forms-api',
    '../platform-api/packages/workflows-api'
  ]
}
`}</code></pre>
    <p>{`Running `}<inlineCode parentName="p">{`build up`}</inlineCode>{` in the same directory as that file will run `}<inlineCode parentName="p">{`builder-ui`}</inlineCode>{` and all of the base platform services locally so you can develop on them. It will also pull down and run all the dependencies needed to run these services. Each path in the run array needs to point to a directory containing a `}<inlineCode parentName="p">{`build.service.json5`}</inlineCode>{` file (which each of our services has).`}</p>
    <p>{`The full set of docs for the build cli can be found at: `}<a parentName="p" {...{
        "href": "https://github.com/kualibuild/build-cli"
      }}>{`https://github.com/kualibuild/build-cli`}</a></p>
    <h2 {...{
      "id": "how-does-builder-ui-fit-into-the-bigger-picture"
    }}>{`How does builder-ui fit into the bigger picture?`}</h2>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "https://kuali.slite.com/app/channels/NHqDBHGF7m/notes/OWvG8GzRWY"
      }}>{`https://kuali.slite.com/app/channels/NHqDBHGF7m/notes/OWvG8GzRWY`}</a>{` for more info on that.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      